import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideoFTP from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/YoutubeVideoCardFritzNAS";
import YoutubeVideoWin from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/YoutubeVideoCardFritzStorageWin";
import YoutubeVideoMacOS from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/YoutubeVideoCardFritzStorageMac";
import FritzCards from "components/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/FritzCards";
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "AVM Fritzbox as FTP Server",
  "path": "/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use the FTP service of your router or network drive to back up your alarm video and images.",
  "image": "MD_SearchThumb_RouterAsFTPServer_Fritzbox.png",
  "social": "/images/Search/MD_SearchThumb_RouterAsFTPServer_Fritzbox.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Router-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='AVM Fritzbox as FTP Server' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the FTP service of your router or network drive to back up your alarm video and images.' image='/images/Search/MD_SearchThumb_RouterAsFTPServer_Fritzbox.png' twitter='/images/Search/MD_SearchThumb_RouterAsFTPServer_Fritzbox.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/' locationFR='/fr/Motion_Detection/Router_as_a_FTP_Server/AVM_Fritzbox_as_FTP_Server/' crumbLabel="Fritzbox" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "router-als-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#router-als-ftp-server",
        "aria-label": "router als ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Router als FTP Server`}</h1>
    <h2 {...{
      "id": "avm-fritzbox-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#avm-fritzbox-as-ftp-server",
        "aria-label": "avm fritzbox as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`AVM Fritzbox as FTP Server`}</h2>
    <YoutubeVideoFTP mdxType="YoutubeVideoFTP" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`In the following we want to explore the possibility to use an AVM Fritzbox 7490 as our FTP server. The user interface screenshots will only show the AVM UI. But most of the steps we will take are representative for other routers as well. Each time, you will need to define a FTP user and the corresponding access rights inside the router. Then type in the FTP user credentials and the corresponding FTP directory into your camera´s WebUI.`}</p>
    <h3 {...{
      "id": "set-up-an-upload-directory",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-up-an-upload-directory",
        "aria-label": "set up an upload directory permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up an Upload Directory`}</h3>
    <p>{`First we have to set up a directory for your camera´s uploads. Connect an USB thumb drive or an USB hard drive to the USB port of your router. In case of the Fritzbox simply click on FritzNAS (on top of the screen) to enter the storage overview panel. For other routers it is recommended to check the FTP access with an FTP client like `}<OutboundLink href="https://filezilla-project.org/download.php?type=client" mdxType="OutboundLink">{`Filezilla Client`}</OutboundLink>{` or simply type in `}<em parentName="p">{`ftp://`}{`[IP of your router]`}{`:21`}</em>{` into the address bar of your default web browser. Use the camera login we just created to check available storage. If you are using Firefox, you can use a FTP client extension like `}<OutboundLink href="https://addons.mozilla.org/en-US/firefox/addon/fireftp/" mdxType="OutboundLink">{`FireFTP`}</OutboundLink>{` to create a directory that your camera should use to upload files.`}</p>
    <p>{`Once inside the FritzNAS storage overview window, change to your external storage (in our case a ADATA Thumbdrive), click on the New Folder button on top and create a new folder for your camera. Remember the path to your folder as we will have to type it into camera webUI in moment. Be careful not to delete the FRITZ folder, created by your router - if you do, your thumb drive will be unmounted and you have to re-plug it into the USB Port!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/48e5ead54c5077d47793d2180bd3eae5/3dde1/FTP-Fritzbox-7490de_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABXElEQVQY0yWMTU/bQBRF/bf7VyihC9RFVkCFqOq2FBFcRwESB3vsyTix573xzJsPG1ohUbKrkkhHV7pHujc6v5w8nH4Yj798jKtRnI/i4jguPn1nZ5Py4q68SMrxDTv+VpzExWjvP19Xpz/Lo6/5ZcqjZVnV2dVjvpxyOWNixuoDC15nvJ5X9T0T01wkTzx54rcZn2RVsuS3i/KerSLnO9P7QDBkSeh13+sQdun8HtdZi2TBWbAEREikgtfBa2sx2uR5m07l7xR/xDifyxWHFUexaqpyzRgI0dVCQ2NJE2ndISIohQiA2Ea6FbJcYl2TQlMxff3r+eHRp+kwm71m2XtRbIvinfPt9u3f2ytRZ7SSbdNs1rqDiMwaYaENhWB978k78lYZTc6+/H0e/gzkd34YwtB778hZAtkqBEsqok6odk5E3podu60J+xNnjbOm3aw7hEM9JMgGpCTC/7LrZsHzA5HiAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48e5ead54c5077d47793d2180bd3eae5/e4706/FTP-Fritzbox-7490de_04.avif 230w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/d1af7/FTP-Fritzbox-7490de_04.avif 460w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/7f308/FTP-Fritzbox-7490de_04.avif 920w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/7a54e/FTP-Fritzbox-7490de_04.avif 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/48e5ead54c5077d47793d2180bd3eae5/a0b58/FTP-Fritzbox-7490de_04.webp 230w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/bc10c/FTP-Fritzbox-7490de_04.webp 460w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/966d8/FTP-Fritzbox-7490de_04.webp 920w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/5304f/FTP-Fritzbox-7490de_04.webp 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/48e5ead54c5077d47793d2180bd3eae5/81c8e/FTP-Fritzbox-7490de_04.png 230w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/08a84/FTP-Fritzbox-7490de_04.png 460w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/c0255/FTP-Fritzbox-7490de_04.png 920w", "/en/static/48e5ead54c5077d47793d2180bd3eae5/3dde1/FTP-Fritzbox-7490de_04.png 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/48e5ead54c5077d47793d2180bd3eae5/c0255/FTP-Fritzbox-7490de_04.png",
              "alt": "AVM Fritzbox FTP Service",
              "title": "AVM Fritzbox FTP Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a directory for your camera on your external storage medium to write her files to.`}</p>
    <h3 {...{
      "id": "set-up-a-ftp-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-up-a-ftp-user",
        "aria-label": "set up a ftp user permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up a FTP User`}</h3>
    <p>{`First, we have to find the FTP settings inside the router. For most routers that is pretty straight-forward - just search for the keyword FTP. Unfortunately, AVM decided to hide this function with the newer firmware, causing us a little bit of a headache. Which is partly the reason why we take the Fritzbox router as the example - the other routers will be much easier to set up.`}</p>
    <p>{`The FTP are now hidden inside the Fritzbox User (Benutzer) tab under System. Click on Add User (Benutzer Hinzufügen) and give your user a name and password. It is recommended to keep the name and password secure but to stay away from exotic characters, which might cause you some trouble. Especially, if you only use the FTP upload inside your local network and you deactivate the access rights from the internet (Zugang aus dem Internet, s. screenshot below). The only thing your cameras needs to have access to is the network attached storage (Zugang zu NAS-Inhalten, s. below). Click on add a directory (Verzeichnis Hinzufügen) and allow the camera to write onto the supplied storage.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7f729c7845171c3292a48799cd7252a9/6acbf/FTP-Fritzbox-7490de_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAD1klEQVQ4y3WUW2/cRBiG959yz58o4nDTC665QEhcFFVFpRRRkEgbQtu0NKmSkNLs0fba67M9Hp/Wh9lkHzTehUIlLD16Z0bW62/e+cajo88/4MfbH3Lr3pxPHs74+MGUW99N+eKxwTfPLe48M/nqV5MvDw3uPLO4/Wj3nubTh7vxZz/M+ej+BCOUjN4+/5pXR3d5cGLz07nJz+cWj84sDi9Njq8sfntjDjz5w+DojcmDl1PuHU/4/tWMb4/HPDyZc//FhLvPrwiylFFSVuyeligXjG2XhRewFCVLUQ1qiwo7q3BkzSyUjL0MK8mZh1oLFpFkEuaUbc7o4s8xTZ3TtRI/8lhYNsZyiek4mLbDYqnnNoa9my9Xq2Gu1029bi1ZmBZeEND1GSN75aC6ErUpyLwFyeU5tTGhWoxprRntcjZoY01pB2Y0xoTGNekaSbeWtOuMrslRKmHkuC7XfUV/XVA5U7zpmCj2WFcZm76ilIK6ytmoFtU3qG6N2nR0joVaS1Rf7QrqKpSKGDmOTVvnbLYFjTNGjN9Sxx6bLKJPA3J3hbAdcs+nSZIdQrCejunXkr4r6duCvi1Rymc0XVi4fkjbC4rIIJlckVkzCtugDRwqx0Rac0rbpA1WNBrfGVRv8x0FvVoxOjozOZkEeGnIeu0TpSFx4iOyCJknFKWg39QDXV/RqX/RFHu0YYnql4yKfMlGRWyUTZHb+CsboTMsUtpa7rfzjv9WpbujoGv/Nlwwggu223O4OaPvI+IkxPVsXM8hCFbEwYpor3HgIhKfQsbkWTSo2meo2hrVTxltb04Z4JQuXw4Zum8uSKdXpLZBJqLBJI19ksgjSwPKPBkMtRYyGlhXum3easMXaOB3WvcS9+Ax9tEh9i+/EF+ckZeCLPEpZUy7llRFSl1qxD9URULf6AovteFT2D5le3OEUgGyyAgjd+jFIHCI/B1p7CFFSJ6FQ8Zp5FJkEXWRUub6YzrDc214CNtDtjePUd2KPBOEgU9ZSKqyGMhESpokJHFEGAS4nkeYxIg8o1UtMhdUla7wtTZ8AlvNAV2rr2HD9abhZtMOXG9a+q6ia6vhJHvVsLYMxPELslcnyNevyZKINBF03cnOcMcBfbtCJBFx6AwnqMPW2enw9dYqrZUg922i2RXRfEJqTIl8GylSVH/6nmGns9rltK7EPvx36LWmzmh13/W62mpA/yQ2+p6rvSHacHtA26wopCRLI/IsJc+SvaZIEVOXcth6XeZDm1SFHNaGcVnRd6fvZdhN6DufvnP/l6ZxEOkcKQ3C4Io4niDSGWlioNRL/gLe5swxQng73gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7f729c7845171c3292a48799cd7252a9/e4706/FTP-Fritzbox-7490de_01.avif 230w", "/en/static/7f729c7845171c3292a48799cd7252a9/d1af7/FTP-Fritzbox-7490de_01.avif 460w", "/en/static/7f729c7845171c3292a48799cd7252a9/7f308/FTP-Fritzbox-7490de_01.avif 920w", "/en/static/7f729c7845171c3292a48799cd7252a9/b96d9/FTP-Fritzbox-7490de_01.avif 1001w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7f729c7845171c3292a48799cd7252a9/a0b58/FTP-Fritzbox-7490de_01.webp 230w", "/en/static/7f729c7845171c3292a48799cd7252a9/bc10c/FTP-Fritzbox-7490de_01.webp 460w", "/en/static/7f729c7845171c3292a48799cd7252a9/966d8/FTP-Fritzbox-7490de_01.webp 920w", "/en/static/7f729c7845171c3292a48799cd7252a9/4ad2e/FTP-Fritzbox-7490de_01.webp 1001w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7f729c7845171c3292a48799cd7252a9/81c8e/FTP-Fritzbox-7490de_01.png 230w", "/en/static/7f729c7845171c3292a48799cd7252a9/08a84/FTP-Fritzbox-7490de_01.png 460w", "/en/static/7f729c7845171c3292a48799cd7252a9/c0255/FTP-Fritzbox-7490de_01.png 920w", "/en/static/7f729c7845171c3292a48799cd7252a9/6acbf/FTP-Fritzbox-7490de_01.png 1001w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7f729c7845171c3292a48799cd7252a9/c0255/FTP-Fritzbox-7490de_01.png",
              "alt": "AVM Fritzbox FTP Service",
              "title": "AVM Fritzbox FTP Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a FTP user on your router so your camera has access to the network attached storage.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bedba4d3149ea192093d0ed5ba281a8f/ec3e2/FTP-Fritzbox-7490de_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98.69565217391303%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADIklEQVQ4y2WSaW/bRhCG+c/7R/qtjQP0S9s0TeyiqdXajiTDtpTYosjlHrPH7EXqsKzLso6CdJvW7eDBYAHimR2+ZHL8y8XNd1/9+P3xty3y6rfBq9P06DQ97ubH3ex9N/vhYnjSzX9qZ0et4VErfd2qn75uDb/5MHh/mSfn3Zu71te/nl687ZKTy/TkcnjcTc962Vkv+703PL1OT6/SD1fpm4u7d530bfvuXWfwc3vw5uKudZMmaKUJ4eG+nI6RSiBCMKU4OmYsR8fR5WAKhbcFZKCHXBXS5ELloIlUSf/2jhASvDEWcgFE6kxqIg0FmVGeM4Gh9OWomkyqyT36MBjmaUbSjICSSZoNpRQxGB/1SDLb72O/Z28/lx6DM9NxOZ9NlvPpdrPcP60WDxOH4K10Vgavallr2chqBqLqdN3Hjm13Z5PJdvu03233u6fd7mm73ex2T/P5LHgTA8aAtSw45ZyVAZ3nj9vN5AGr+/vH/WG1Wu92u8M/tT8cDovF3DsdvGlQCRcsy4kx0gexXk5LzEpfHl7WvqnD4TBfPDirvNM1Xia9YdHpk34mtGWbx9mkgtFo2gj/nfB889+yqWWlcqNpDML7fLm4Xy1n6/Vys1k3rDab9Wq1mM2ms9l0PB6Nx9Vf19YyJKOqP6r6VXkT422IaIwyBrQCpequFYBgtCAArCA5COpQNS+MwYukKq8brsr4yaKkRc5oLoGCoBK4BCaBKsnRgEVlNGA9mqOR3vGkKi8bumXsGSMoyWiRC14YDQ3ConKo0EiLDUbaZlDwtdxpaMd47ZzijHBGaJExWh8EJwqYVlw1K0igaCQaQCODZ0lVXjScx3jpnRaCSxBoNKLRWkkJAAKEYIwyxkBwRO0cKqVds/Z5w9mzHKOL0ZXRl9HHYL1D79E7DN4qCSAEgNBaggCjixeyRQm8kMCMFs9gk1CNrmPnjICosxScOfyXXNYygKBfgvkSkrPKWRW8fv43m25jeLm2tfr58xotmw66ydw745yxqJ3VaJS12qJxliYxnDX8EXzbInGW/h+LhZKZECmItCCfAIaMDpT6/Cd24iONcLAD6gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bedba4d3149ea192093d0ed5ba281a8f/e4706/FTP-Fritzbox-7490de_02.avif 230w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/d1af7/FTP-Fritzbox-7490de_02.avif 460w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/7f308/FTP-Fritzbox-7490de_02.avif 920w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/cc3cf/FTP-Fritzbox-7490de_02.avif 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bedba4d3149ea192093d0ed5ba281a8f/a0b58/FTP-Fritzbox-7490de_02.webp 230w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/bc10c/FTP-Fritzbox-7490de_02.webp 460w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/966d8/FTP-Fritzbox-7490de_02.webp 920w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/c5bb7/FTP-Fritzbox-7490de_02.webp 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bedba4d3149ea192093d0ed5ba281a8f/81c8e/FTP-Fritzbox-7490de_02.png 230w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/08a84/FTP-Fritzbox-7490de_02.png 460w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/c0255/FTP-Fritzbox-7490de_02.png 920w", "/en/static/bedba4d3149ea192093d0ed5ba281a8f/ec3e2/FTP-Fritzbox-7490de_02.png 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bedba4d3149ea192093d0ed5ba281a8f/c0255/FTP-Fritzbox-7490de_02.png",
              "alt": "AVM Fritzbox FTP Service",
              "title": "AVM Fritzbox FTP Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choosing "Alle an der Fritzbox verfügbaren Speicher" allows the camera to write into every directory on your router.`}</p>
    <p>{`It is very important, that the user authentication is active on your router - the camera will not be able to upload the files to the storage otherwise. As seen in the screenshots below, you will now have an administrator account to manage your router as well as the pure FTP user for your camera. You can switch to the tab Anmeldung im Heimnetz to confirm that the authentication is active or not... Well, you will already know if you had to login to your router earlier anyway. Check the screenshots below for the Fritzbox example.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/4cdf7/FTP-Fritzbox-7490de_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAChUlEQVQ4y4WSW28bRRiG97dywZ+pBLSCq6oVpUUtSCCBWpdygZrkoiWNSUjtsLHjjfd8mMOeD05tP2jXwaLccPHo+b5Xo/lGM2OMfpswefwpz599x/3DkIcHSx4c2Nx/bfPTicfLU58Xf/j8cOzy89hjdOrz9ZHDw8OPeXDo8OjIxvj+1Zjxk0948ugb7oyu+PKXC7761eTeK5OnR5f8+OaSZ0cmTw9NHr++4NuDv7g7mvDZiwl3R1O+2HvK58/PMTabgmpzA2yBBl0o/CQgkhFJniHrGlFVg4M0I8gKrhOJFUuCNMVOJIFOcYTCVinG2dTEnF+xagvKWjNzfC4sjz9nNqemzfncY+ELnETvcUU68N+6x5hbC4IoYL0qWXUKES6J/SUqckkCe6h17KFjFxk6qN6Rg4zcIe/X9XlPnSe7DbUWrG9Kuk4SRA5e4CF1glQxiYxQOkFpMfQ67fM+i1G3fVWntF3B6qbCiCIPz/O46U/YRMSXU/zJFDGbIeZz5HxOZJo45+dDVtkWZc9yscNeUDvXO5ZXGFoFuI5N0+R80Bbx2RjlBbRJQpsIWiGowojM86jjhCYRe/9DJyTB4hr57neM6bXHwanFySxCK4f8akJm7aZWPXY/fUntLnf9v/Ly9rTxxRTr3Qne8VuMIHZwg4CiTKlbl6ZTFKWmKHorykqTF3JwWepbq+He1uuGD+t6yPNcUVQpBpv3sJ0A79mup4T+Ai1CqlxSZoK6UENdFfJj55K20jSVpqtTuiZj1WQYbMdsN2PYnrBZnxEGFknkU2SCPE32ZAPxvk91vMt69y8uQrSMMLabt/SwfcNmfTz8xVVX0LXZ/5Dv67bNkCJEiJC/AdgcuO42Skl6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7dfe8bbc17829b4658efa57a2553c8f/e4706/FTP-Fritzbox-7490de_03.avif 230w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/d1af7/FTP-Fritzbox-7490de_03.avif 460w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/7f308/FTP-Fritzbox-7490de_03.avif 920w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/5075a/FTP-Fritzbox-7490de_03.avif 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7dfe8bbc17829b4658efa57a2553c8f/a0b58/FTP-Fritzbox-7490de_03.webp 230w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/bc10c/FTP-Fritzbox-7490de_03.webp 460w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/966d8/FTP-Fritzbox-7490de_03.webp 920w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/157c9/FTP-Fritzbox-7490de_03.webp 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7dfe8bbc17829b4658efa57a2553c8f/81c8e/FTP-Fritzbox-7490de_03.png 230w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/08a84/FTP-Fritzbox-7490de_03.png 460w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/c0255/FTP-Fritzbox-7490de_03.png 920w", "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/4cdf7/FTP-Fritzbox-7490de_03.png 995w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7dfe8bbc17829b4658efa57a2553c8f/c0255/FTP-Fritzbox-7490de_03.png",
              "alt": "AVM Fritzbox FTP Service",
              "title": "AVM Fritzbox FTP Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please make sure that you also have an administrator account active and...`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/5caea/FTP-Fritzbox-7490de_03-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACKUlEQVQoz02PWW/TUBBG/RP5T4BEyyNPICEkKqjKQ4tEUxCiS0gb1Kjpkqo0i5fYjp3Y19d2Yie201YH2UGFh6Mzd2Z09Y2yud2k/eoJ715v8HzPYu1Lnxe7A9Z2B2yeDPnQMPh4bPBmX6vebw8N1msa6zW1Yq2m8nJP4+nnPrULB6XeuuH2xzO+fj9g69Rkp6Wx09LZbukcXRscdgz2r3S+X2h8aw/YPVPZqN+y1Ryw2ejyqdln66TH+3qXxu8hSjqXTPMCeADmmJ6Hao/QXRdLThnFaYUTpxgixgpn3I4EwyBGnUjMIEb3JJof4UQhyuVNl76qssxnJKmkp5lowxG6aTPQhxi2w0QIvCDAD4LKIpT4UiLCsHJJEEXM0ghloGtI6XNfJORZgBAOvu8ggzG+N6oQvlNR9j3XIpZjkqlgFvsksV85X8QsixBFGxr/PswFXvca96KNe3XOuHOOe9lGdm9ItB6pMaguWRbJyv9RZDOKXKIEwsV1RtyXgzvBTO+iHR6gHh2h/6xjNOrYv5pYJ8e4Z6eIyQhvbFdJ/bGN8Fapk6nkbilRXM+hpw7xA0FeTFgsIix7iGnqWJaBZRv4vksQTJChhxRjpHBXlH3fqZgnEcsiQOloDscdmytdECcu80QQlIueU7lMkU7l41ml88WUbB4/ushKz8gzDyWZaSxSEx5cloVGthDkWUSehRVFvqqzhawoa+HZjGwd/69l4DJxbZLE4g+9MdcH9xc9oQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/e4706/FTP-Fritzbox-7490de_03-1.avif 230w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/d1af7/FTP-Fritzbox-7490de_03-1.avif 460w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/7f308/FTP-Fritzbox-7490de_03-1.avif 920w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/c91b9/FTP-Fritzbox-7490de_03-1.avif 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/a0b58/FTP-Fritzbox-7490de_03-1.webp 230w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/bc10c/FTP-Fritzbox-7490de_03-1.webp 460w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/966d8/FTP-Fritzbox-7490de_03-1.webp 920w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/ef2bd/FTP-Fritzbox-7490de_03-1.webp 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/81c8e/FTP-Fritzbox-7490de_03-1.png 230w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/08a84/FTP-Fritzbox-7490de_03-1.png 460w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/c0255/FTP-Fritzbox-7490de_03-1.png 920w", "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/5caea/FTP-Fritzbox-7490de_03-1.png 996w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f329a60cc8bcd544c4ae8d3d40bf8ade/c0255/FTP-Fritzbox-7490de_03-1.png",
              "alt": "AVM Fritzbox FTP Service",
              "title": "AVM Fritzbox FTP Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`...the user authentication is activated - this is very important and a common mistake!`}</p>
    <h3 {...{
      "id": "connect-your-fritzbox-as-a-network-drive",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connect-your-fritzbox-as-a-network-drive",
        "aria-label": "connect your fritzbox as a network drive permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connect your Fritzbox as a Network Drive`}</h3>
    <p>{`You can map the FTP storage as a network drive for easier file management.`}</p>
    <YoutubeVideoWin mdxType="YoutubeVideoWin" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideoMacOS mdxType="YoutubeVideoMacOS" />
    <h3 {...{
      "id": "set-up-your-camera-to-use-the-ftp-storage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-up-your-camera-to-use-the-ftp-storage",
        "aria-label": "set up your camera to use the ftp storage permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up your Camera to use the FTP Storage`}</h3>
    <p>{`Now we can access our camera to program it to upload files to our new FTP upload directory in case of an alarm event. Open the web user interface of your camera and navigate to ''Alarm/FTP''. The following menu (see 2nd screenshot below) needs to be filled with all the data we just set up inside the router:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`FTP Server`}</strong>{`: Is the address of the FTP server. In our case the IP address of our router 192.168.178.1. Please be aware that the IP addresses of routers from different manufactures will vary. Usually it is only the third number that will be different - in most cases it is either 0, 1, 2 or in case of the AVM Fritzbox 178.`}</li>
      <li parentName="ul"><strong parentName="li">{`FTP Port`}</strong>{`: The default FTP port is 21.`}</li>
      <li parentName="ul"><strong parentName="li">{`FTP Folder`}</strong>{`: The FTP folder is the upload directory we just set up on our router. Please remember to add a / in front of the folder. If you want to upload to the root directory, just put a / here.`}</li>
      <li parentName="ul"><strong parentName="li">{`FTP Username`}</strong>{`: Put in the username we just defined inside the router...`}</li>
      <li parentName="ul"><strong parentName="li">{`FTP Password`}</strong>{`: ... and add the corresponding password.`}</li>
      <li parentName="ul"><strong parentName="li">{`FTP Mode`}</strong>{`: The default mode for a local FTP server - that isn´t shielded by a NAT firewall - is PASV.`}</li>
    </ul>
    <p>{`Afterwards click on Submit and Test to check your inputs. Your camera should give you a Successful notification and if you check your upload directory, you will find a snapshot uploaded by the camera. Now you can activate the FTP upload actions in case of an alarm trigger event, to have the camera upload it snapshots or videos to your FTP server.`}</p>
    <h3 {...{
      "id": "configure-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-your-camera",
        "aria-label": "configure your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure your Camera`}</h3>
    <FritzCards mdxType="FritzCards" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      